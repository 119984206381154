import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { useTranslation } from 'next-i18next';
import { Fragment, useRef, useState } from 'react';
import { TransactionType } from 'shared/dist/types';

import { useOutsideAlerter } from '@/src/hooks/use-outside-alerter';

export default function TransactionTypeDropdown({
  transactionType,
  setTransactionType,
  filterSection = false
}: {
  transactionType: TransactionType;
  setTransactionType: any;
  filterSection?: boolean;
}) {
  const [showTransactionTypes, setShowTransactionTypes] = useState<boolean>(false);
  const openDropdownRef = useRef(null);
  const { t } = useTranslation('common');

  useOutsideAlerter(openDropdownRef, setShowTransactionTypes);

  const handleClick = (type: TransactionType) => {
    setTransactionType(type);
    setShowTransactionTypes(false);
  };

  return (
    <Popover
      ref={openDropdownRef}
      className={cls('relative text-gray-500 dark:text-gray-400', {
        'w-full': filterSection,
        'w-[256px]': !filterSection
      })}>
      <>
        <Popover.Button
          onClick={() => setShowTransactionTypes(!showTransactionTypes)}
          className={cls(
            'overflow-hidden w-full flex items-center justify-between gap-1 leading-3 rounded-t-md border border-gray-200 dark:border-dark-morefaded bg-white dark:bg-dark-primary px-2',
            {
              'rounded-b-md': !showTransactionTypes,
              'h-[24px]': !filterSection,
              'h-8': filterSection
            }
          )}>
          <div className="text-xs">{t(transactionType)}</div>

          <ChevronRightIcon
            className={cls(
              'text-bobo-prop dark:text-dark-prop w-3 h-3 transition-all duration-200',
              { 'rotate-90': showTransactionTypes }
            )}
          />
        </Popover.Button>

        <Transition
          as={Fragment}
          show={showTransactionTypes}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1">
          <Popover.Panel
            className={cls('absolute left-0 transform translate-x-0 z-50 w-full', {
              'top-5': !filterSection,
              'top-6': filterSection
            })}>
            <div
              className={cls(
                'bg-white dark:bg-dark-primary w-full border-x border-b border-gray-200 dark:border-dark-morefaded rounded-b-md overflow-hidden shadow-sm flex flex-col pt-1'
              )}>
              {Object.values(TransactionType)
                ?.filter((type) => type !== transactionType)
                ?.map((type: TransactionType, idx) => {
                  return (
                    <div
                      key={idx}
                      className="py-1 px-2 transition-colors duration-200 hover:bg-bobo-proplight dark:hover:bg-dark-prophover text-xs">
                      <button onClick={() => handleClick(type)} className="w-full h-full text-left">
                        {t(type)}
                      </button>
                    </div>
                  );
                })}
            </div>
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}
