import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { GotoFeature } from 'shared/dist/types';
import { capFirstLetter } from 'shared/dist/util/string';

import { useSlideIn } from '@/src/context/slide-in-context';
import { getColorForObjectType } from '@/src/lib/colors';
import { TAX_TYPE_SHORT } from '@/src/lib/constants';
import { LabelWrapper, PropLabelsSingle } from '@/src/widgets/prop-labels';

export const getTransactionBuyersAndSellers = (trans) => {
  const buyers: any[] = [];
  const sellers: any[] = [];

  if (trans?.buyer && trans?.buyer?.length > 0) {
    trans?.buyer?.map((actor) => {
      const address: string =
        actor?.address_generic ?? '' + actor?.county ?? actor?.postal_area ?? '';

      buyers.push({
        actor_id: actor.actor_id ?? '',
        name: actor?.company_name ?? actor?.name,
        address: address !== 'undefined' ? address : ''
      });
    });
  }

  if (trans?.seller && trans?.seller?.length > 0) {
    trans?.seller?.map((actor) => {
      const address: string =
        actor?.address_generic ?? '' + actor?.county ?? actor?.postal_area ?? '';

      sellers.push({
        actor_id: actor.actor_id ?? '',
        name: actor?.company_name ?? actor?.name,
        address: address !== 'undefined' ? address : ''
      });
    });
  }

  // Handle buyer actors without IDs
  if (trans?.buyer_actors_without_id?.length > 0) {
    trans?.buyer_actors_without_id?.map((actor) => {
      const name: string = actor.split(',').reverse().join(' ').replace(';', '');
      buyers.push({
        actor_id: null,
        name: name
      });
    });
  }

  // Handle seller actors without IDs
  if (trans?.seller_actors_without_id?.length > 0) {
    trans?.seller_actors_without_id?.map((actor) => {
      const name: string = actor.split(',').reverse().join(' ').replace(';', '');
      sellers.push({
        actor_id: null,
        name: name
      });
    });
  }

  return { buyers, sellers };
};

export const getTransactions = (
  setTransactions,
  setTransactionsLoading,
  apiFunction: string,
  bodyObject?: any | null,
  setSlideIn?,
  t?
) => {
  let body = {};
  if (bodyObject) {
    body = bodyObject;
  }

  setTransactionsLoading(true);
  fetch(`/api/transactions/${apiFunction}`, {
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((json) => {
      if (json?.transactions?.length > 0) {
        const transactionsData = json?.transactions?.map((trans) => {
          const { buyers, sellers } = getTransactionBuyersAndSellers(trans);

          const date =
            trans?.date && trans?.date?.length > 0 ? (
              trans?.date
            ) : (
              <span className="text-gray-400 italic">{t('general.date-missing')}</span>
            );

          const { color, bgColor } = getColorForObjectType(trans.type_code ? +trans.type_code : 0);

          const typeCodeDiv = (
            <div className="md:pr-1 has-tooltip relative">
              <LabelWrapper color={color} bgColor={bgColor}>
                {trans.type_code}
              </LabelWrapper>

              <div className="tooltip absolute top-6 text-xs py-1 px-2 bg-white dark:text-white dark:bg-dark-lessfaded text-bobo-black rounded-md shadow-md">
                <LabelWrapper color={color} bgColor={bgColor}>
                  {capFirstLetter(t(TAX_TYPE_SHORT[trans.type_code]))} [{trans.type_code}]
                </LabelWrapper>
              </div>
            </div>
          );

          if (apiFunction === 'get-transactions-for-property') {
            return {
              date: date,
              sum: Math.round(trans?.sum),
              buyer: buyers,
              seller: sellers,
              typeCode: typeCodeDiv,
              transactionType: trans?.transactionType,
              updatedAt: trans?.updated_at
            };
          } else {
            const inCrm: boolean = apiFunction === 'get-transactions-for-properties';

            const propLabelsCss: string = inCrm ? '!justify-end' : '!justify-end md:!justify-start';

            const buyerDiv = (
              <div className={cls('flex flex-col gap-1 text-right', { 'md:text-left': !inCrm })}>
                {buyers?.map((actor, idx) => {
                  if (!actor?.actor_id) {
                    return <div key={idx}>{actor?.name}</div>;
                  } else {
                    return <Company key={idx} owner={actor} />;
                  }
                })}
              </div>
            );

            const sellerDiv = (
              <div className={cls('flex flex-col gap-1 text-right', { 'md:text-left': !inCrm })}>
                {sellers?.map((actor, idx) => {
                  if (!actor?.actor_id) {
                    return <div key={idx}>{actor?.name}</div>;
                  } else {
                    return <Company key={idx} owner={actor} />;
                  }
                })}
              </div>
            );

            const propDiv = (
              <div className="flex flex-col w-full">
                <button
                  onClick={() =>
                    setSlideIn({
                      type: GotoFeature.PROPERTY,
                      object: trans?.property
                    })
                  }
                  className={cls(
                    'capitalize text-wrap break-words hyphens-auto font-demi text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop dark:hover:text-dark-prophover transition-colors duration-200 text-right',
                    { 'md:text-left': !inCrm }
                  )}>
                  {trans?.property?.authority_nickname}
                </button>

                <div>
                  <PropLabelsSingle
                    object={trans?.property}
                    className={`${propLabelsCss} !items-center`}
                    short
                  />
                </div>
              </div>
            );

            const sumDiv = (
              <NumberFormat
                displayType="text"
                value={trans?.sum ?? 0}
                thousandSeparator={' '}
                prefix={t('general.sek-prefix')}
                suffix={t('general.thousand-suffix')}
              />
            );

            return {
              property: propDiv,
              buyer: buyerDiv,
              seller: sellerDiv,
              sum: sumDiv,
              date: date,
              typeCode: typeCodeDiv,
              transactionType: inCrm ? t(trans?.transactionType) : trans?.transactionType,
              updatedAt: trans?.updated_at
            };
          }
        });
        setTransactions(transactionsData);
      }
    })
    .finally(() => setTransactionsLoading(false));
};

export function Company({ owner }: { owner: any }) {
  const { t } = useTranslation('common');
  const { setSlideIn } = useSlideIn();

  return owner.actor_id && owner.actor_id?.length === 10 ? (
    <div className="max-w-full">
      <button
        onClick={() =>
          setSlideIn({
            type: GotoFeature.COMPANY,
            object: owner
          })
        }
        className="capitalize text-right md:text-left break-words hyphens-auto max-w-full font-demi text-bobo-prop hover:text-bobo-prophover dark:text-dark-prop dark:hover:text-dark-prophover transition-colors duration-200">
        {owner?.name}
      </button>
    </div>
  ) : owner.actor_id?.length === 12 || owner.name === 'Privatperson eller avslutat bolag' ? (
    <div>{owner.company_name ?? owner.name}</div>
  ) : (
    <div className="italic">{t('general.missing-data-point')}</div>
  );
}

export const transactionHeaders = [
  {
    title: 'general.property-heading',
    cols: 2,
    data: 'property'
  },
  {
    title: 'transactions.buyer',
    cols: 2,
    data: 'buyer'
  },
  {
    title: 'transactions.seller',
    cols: 2,
    data: 'seller'
  },
  {
    title: 'transactions.price',
    cols: 1,
    data: 'sum'
  },
  {
    title: 'general.loa',
    cols: 1,
    data: 'loa'
  },
  {
    title: 'general.boa',
    cols: 1,
    data: 'boa'
  },
  {
    title: 'transactions.typecode-at-transaction',
    cols: 2,
    data: 'typeCode'
  },
  {
    title: 'general.date',
    cols: 1,
    data: 'date'
  }
];
