import 'moment/locale/sv';
import 'styles/index.scss';

import { UserProvider } from '@auth0/nextjs-auth0';
import moment from 'moment';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import { isMobile } from 'react-device-detect';
import { setGlobal } from 'reactn';
import { PropertyTypeGroup } from 'shared/dist/types';
import { ViewMode } from 'shared/dist/types/advanced-search';
import { BboxProvider } from 'src/context/bbox-context';
import { DialogProvider } from 'src/context/dialog-context';
import { DisplayViewProvider } from 'src/context/display-mode-context';
import { MenuModeProvider } from 'src/context/menu-mode-context';
import { PropertyTypesProvider } from 'src/context/propertytype-context';
import { SlideInProvider } from 'src/context/slide-in-context';
import SystemMessageBanner from 'src/widgets/system-message-banner';

import { HideBRFContextProvider } from '@/src/context/hideBRF-context';
import { MapStyleProvider } from '@/src/context/map-context';

type LastView = {
  bbox: number[];
  pitch: number;
  bearing: number;
  zoom: number;
};

setGlobal({
  bgLoading: false as never,
  crmIncrement: 0 as number,
  searchStr: '' as string,
  groupByOwn: false as boolean,
  addedByMe: false as boolean,
  showThumbnails: true as boolean,
  compact: false as boolean,
  openFolders: [] as string[],
  selected: [] as string[],
  comparableProps: [] as string[],
  lastMapView: {} as LastView,
  globalFilter: null as any,
  disableMapInteraction: false as boolean,
  dataView: ViewMode.SUMMARY as ViewMode,
  showFilters: false as boolean,
  showDrawingTools: false as boolean,
  hideResultPane: true as boolean,
  hideSubMenu: isMobile ? true : (false as boolean),
  savedSearch: null as string | null,
  subMenuRefresh: 0 as number,
  rentMode: 'overview' as string | PropertyTypeGroup,
  view: 'grid' as string,
  globalShowEnv: false as boolean,
  activeFiltersCount: 0 as number
});

function App({ Component, pageProps }: AppProps): JSX.Element {
  const router: any = useRouter();
  moment.locale(process.env.NEXT_PUBLIC_STAGE !== 'production' ? router.locale : 'sv');

  const datadogClientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
  const datadogApplicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
  const datadogEnv = process.env.NEXT_PUBLIC_STAGE || 'development';

  return (
    <>
      {process.env.NEXT_PUBLIC_DATADOG_RUM_TRACKING_ENABLED === 'true' && (
        <Script id="datadog-rum">
          {`
        (function(h,o,u,n,d) {
          h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
          d=o.createElement(u);d.async=1;d.src=n
          n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
        })(window,document,'script','https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js','DD_RUM')
        window.DD_RUM.onReady(function() {
          window.DD_RUM.init({
            clientToken: '${datadogClientToken}',
            applicationId: '${datadogApplicationId}',

            site: 'datadoghq.eu',
            service: 'app-premium',
            env: '${datadogEnv}',

            // Specify a version number to identify the deployed version of your application in Datadog
            version: '${process.env.NEXT_PUBLIC_BUILD_REVISION || '-'}',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: false, // trackUserInteractions - disabled since tracks potentially sensitive or private data within user clicks in your application
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input'
          });
        })
      `}
        </Script>
      )}
      <UserProvider>
        <HideBRFContextProvider>
          <PropertyTypesProvider>
            <ThemeProvider attribute="class">
              <DialogProvider>
                <SlideInProvider>
                  <DisplayViewProvider>
                    <MenuModeProvider>
                      <MapStyleProvider>
                        <BboxProvider>
                          <SystemMessageBanner />
                          <Component {...pageProps} />
                        </BboxProvider>
                      </MapStyleProvider>
                    </MenuModeProvider>
                  </DisplayViewProvider>
                </SlideInProvider>
              </DialogProvider>
            </ThemeProvider>
          </PropertyTypesProvider>
        </HideBRFContextProvider>
      </UserProvider>
    </>
  );
}

export default appWithTranslation(App);
